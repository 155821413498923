<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="newTaskModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                @click="$emit('close')"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital text-break">
              Create New Task
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Summary</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Write your task summary"
                  v-model="task.taskTitle"
                  v-bind:class="{
                    'input-required': taskFormErr.taskTitle,
                  }"
                />
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label for="" class="form__label">Linked To</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="taskType"
                      @change="changeSearchParams"
                      disabled
                    >
                      <option value="__Select Field Set__" disabled selected>
                        __Select Field Set__
                      </option>
                      <option
                        v-for="(taskType, index) in allTaskType"
                        :key="index"
                        :value="taskType.value"
                      >
                        {{ taskType.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item" v-if="this.taskType === 'Customer'">
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <!-- <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="customerName"
                      track-by="customerName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.customerName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect> -->

                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      disabled
                      :value="
                        currentCus.customerFirstName +
                        ' ' +
                        currentCus.customerLastName
                      "
                    />
                  </div>
                  <div class="form__item" v-else-if="this.taskType === 'Group'">
                    <label class="form__label">Select {{ taskType }}</label>
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Deposit Account'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="savingsAccountName"
                      track-by="savingsAccountName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="sortedDeposits"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Loan Account'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="loanName"
                      track-by="loanName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="sortedLoans"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Group Loan Account'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="groupLoans"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Group Deposit Account'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="groupDeposits"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Branch'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="branchName"
                      track-by="branchName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.branchName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType === 'Centre'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="centreName"
                      track-by="centreName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="apifetch"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.branchName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Assign To</label>
                    <multiselect
                      v-model="assignedPerson"
                      id="ajax"
                      label="name"
                      track-by="name"
                      placeholder="Find Users"
                      open-direction="bottom"
                      :options="users"
                      :custom-label="name"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="selectAssignedUser"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Due Date</label>
                    <input
                      type="date"
                      class="input form__input form__input--lg"
                      placeholder=""
                      v-model="task.dueDate"
                      :min="currentDate"
                      v-bind:class="{
                        'input-required': taskFormErr.dueDate,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <textarea
                  cols="5"
                  rows="5"
                  class="input form__input"
                  placeholder="Profile Note"
                  v-model="task.taskDescription"
                  v-bind:class="{
                    'input-required': taskFormErr.taskDescription,
                  }"
                  :style="
                    taskFormErr.taskDescription
                      ? 'border:1px solid red !important'
                      : ''
                  "
                ></textarea>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    v-else
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                    @click.prevent="createTask"
                  >
                    Save Task
                  </button>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="$emit('close')"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import LoanApiService from "@/core/services/api.loan.service";
import AccessApiService from "@/core/services/access.service";
import GeneralApiService from "@/core/services/general.service";
import moment from "moment";

export default {
  name: "CreateCustomerTask",
  props: {
    show: Boolean,
    close: Function,
    currentCustomer: Object,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      customers: [],
      loading: false,
      customer: {
        pageIndex: 1,
        pageSize: 1000000,
      },
      selectedCustomer: null,
      assignedPerson: null,
      taskType: "Customer",
      task: {
        assignedUserKey: "2150b9d09ecb483d8eff635c9b067d9b",
        createdbyUserKey: "2150b9d09ecb483d8eff635c9b067d9b",
        taskDescription: "",
        taskTitle: "",
        taskLinkKey: "",
        taskLinkType: "",
        dueDate: "",
      },
      taskFormErr: {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      },
      allTaskType: [
        { name: "Customer", value: "Customer" },
        { name: "Group", value: "Group" },
        { name: "Group - Loan Account", value: "Group Loan Account" },
        { name: "Group - Deposit Account", value: "Group Deposit Account" },
        { name: "Customer - Loan Account", value: "Loan Account" },
        { name: "Customer - Deposit Account", value: "Deposit Account" },
        { name: "Centre", value: "Centre" },
        { name: "Branch", value: "Branch" },
      ],
      users: [],
      depositAccounts: [],
      loanAccounts: [],
      groupLoans: [],
      groupDeposits: [],
      currentDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    sortedLoans() {
      const LoansArray = this.loanAccounts;
      return LoansArray.sort((a, b) => a.loanName.localeCompare(b.loanName));
    },
    sortedDeposits() {
      const depositArrays = this.depositAccounts;
      return depositArrays.sort((a, b) =>
        a.savingsAccountName.localeCompare(b.savingsAccountName)
      );
    },
    currentCus() {
      return this.currentCustomer;
    },
  },
  methods: {
    limitText(count) {
      return `and ${count} other customers`;
    },
    resetTaskForm() {
      this.task = {
        assignedUserKey: "",
        createdbyUserKey: "",
        taskDescription: "",
        taskTitle: "",
        taskLinkKey: "",
        taskLinkType: "",
        dueDate: "",
      };
      this.selectedCustomer = null;
      this.assignedPerson = null;
      this.taskType = "Customer";
    },
    changeSearchParams() {
      // if (this.taskType === "Customer") {
      //   this.getCustomers();
      // } else
      if (this.taskType === "Group") {
        this.getGroups();
      }
    },
    name({ name }) {
      return `${name}`;
    },
    getCustomers() {
      GeneralApiService.post("Customer/customers", this.customer).then(
        (resp) => {
          this.customers = resp.data.data.items;
        }
      );
    },
    getUsers() {
      const page = {
        pageNumber: 1,
        pageSize: 10000,
        status: true,
        filters: [],
      };
      AccessApiService.post("ProleanUser/GetUsersWithoutPermission", page).then(
        (response) => {
          this.users = response.data.data;
        }
      );
    },
    getDepositAccounts() {
      const page = {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
      };
      ApiService.post("DepositAccount/GetFilteredDepositAccount", page).then(
        (resp) => {
          this.depositAccounts = resp.data.data;
        }
      );
    },
    getLoanAccounts() {
      LoanApiService.get(
        "LoanAccount/get-loan-accounts?IncludeDeleted=false&PageIndex=0&PageSize=25&SortDesending=true&SortColumn=null"
      ).then((resp) => {
        this.loanAccounts = resp.data.data;
      });
    },
    getGroups() {
      GeneralApiService.post("Group/Groups", this.customer).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    apifetch(query) {
      GeneralApiService.get(`${this.taskType}/search/${query}`).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    ajaxcall(query) {
      GeneralApiService.get(`Customer/search/${query}`).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    changeSelectedKey() {
      this.task.taskLinkKey =
        this.selectedCustomer.customerKey ||
        this.selectedCustomer.groupKey ||
        this.selectedCustomer.assignedCentreKey ||
        this.selectedCustomer.branchKey;
      this.task.taskLinkType = this.taskType;
    },
    selectAssignedUser() {
      this.task.assignedUserKey = this.assignedPerson.userKey;
    },
    clearTaskValidation() {
      this.taskFormErr = {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      };
    },
    validateTaskForm() {
      this.clearTaskValidation();
      if (
        this.task.taskTitle &&
        this.task.dueDate &&
        this.task.taskDescription
      ) {
        return true;
      }

      if (!this.task.taskTitle) {
        this.taskFormErr.taskTitle = true;
      }
      if (!this.task.dueDate) {
        this.taskFormErr.dueDate = true;
      }
      if (!this.task.taskDescription) {
        this.taskFormErr.taskDescription = true;
      }
      return false;
    },
    createTask() {
      if (this.validateTaskForm()) {
        this.loading = true;
        this.task.taskLinkKey = this.currentCustomer.customerKey;
        this.task.taskLinkType = this.taskType;
        ApiService.post("TaskManagement/createtask", this.task)
          .then((response) => {
            if (response.data.status !== false) {
              this.loading = false;
              this.$emit("task-created-successfully", response.data.message);
              this.$emit("close");
              this.resetTaskForm();
            } else {
              this.loading = false;
              this.$emit("error-creating-task", response.data.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("error-creating-task", error.response.data.message);
          });
      } else {
        this.$emit("error-creating-task", "Some form fields are required.");
      }
    },
  },
  created() {
    this.getUsers();
    this.getDepositAccounts();
    this.getLoanAccounts();
  },
};
</script>
